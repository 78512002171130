import React, { useState, useEffect, useCallback } from "react"

// Hook
import useAppContext from "../../../../hooks/useAppContext"
import usePermissionsState from "../../../../hooks/usePermissionsState"
import useProductsApi from "../../../../hooks/Api/useProductsApi"
import useSportStudiesApi from "../../../../hooks/Api/useSportStudiesApi"

// Constants
import { PERMISSIONS, schoolGrades } from "../../../../constants/constants"

// Utils
import { exportCollectionToArray } from "../../../../utils/collection"
import { isNil } from "lodash"

const permissions = [
  PERMISSIONS.PERM_PRODUCTS_CREATE,
  PERMISSIONS.PERM_PRODUCTS_UPDATE,
]

const WITH_SCHOOL_PROG = [1, 4, 6];

const ProductsEdit = ({ onBackToList, selectedProduct, api }) => {
  const {
    context: { userInfo },
  } = useAppContext()

  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [, , , { putProductConfig }] = useProductsApi()
  const [, , , { postProductConfig }] = useProductsApi()
  const [, , , { uploadImage }] = useSportStudiesApi()
  const [, , , { getFormTypes }] = useSportStudiesApi()

  const savedGetFormTypes = useCallback(getFormTypes, [])

  // imageId is added if a picto is uploaded
  const [data, setData] = useState({
    formTypesIds: [1],
    typeId: 2,
    prices: {
      1: {
        value: 0,
      },
      2: {
        value: 0,
      },
    },
    gradePrices: {},
    stocks: null,
    description: {
      1: "",
      2: "",
    },
    name: {
      1: "",
      2: "",
    },
    isPricedWeekly: "false",
    isPaidWithDeposit: false,
  })
  const [formSave, setFormSave] = useState(false)
  const [formError, setFormError] = useState(null)
  const [file, setFile] = useState(null)
  const [fileSave, setFileSave] = useState(false)
  const [formTypes, setFormTypes] = useState([])

  useEffect(() => {
    if (!selectedProduct) {
      const gradePrices = {};
      schoolGrades.forEach((grade) => {
        gradePrices[grade.value] = { value: 0 };
      });
      setData((state) => ({...state, gradePrices }))
    }
  }, []);

  useEffect(() => {
    savedGetFormTypes().then(r => {
      if (r && r.data) {
        setFormTypes(exportCollectionToArray(r.data, "formsTypes"))
      }
    })
    if (selectedProduct) {
      setData({
        ...selectedProduct,
        isPricedWeekly: selectedProduct.isPricedWeekly ? "true" : "false",
      })
    }
  }, [selectedProduct, savedGetFormTypes])

  const handleSubmit = e => {
    e.preventDefault()
    setFormSave(true)
    setFormError(null)

    const product = { ...data }

    product.prices["1"].value = Number(product.prices["1"].value)
    product.prices["2"].value = Number(product.prices["2"].value)
    product.isPricedWeekly = product.isPricedWeekly === "true"
    delete product.priceForForm

    selectedProduct
      ? putProductConfig(selectedProduct.id, product)
          .then(() => {
            onBackToList()
          })
          .catch(errors => {
            setFormSave(false)
            setFormError(errors.response.data.errors[0])
          })
      : postProductConfig(product)
          .then(() => {
            onBackToList()
          })
          .catch(errors => {
            setFormSave(false)
            setFormError(errors.response.data.errors[0])
          })
  }

  const handleFileUpload = async () => {
    try {
      const { data: image } = await uploadImage(file)

      setData({ ...data, imageId: image.id })
      setFileSave(true)
    } catch (error) {
      console.error("error in handleFileUpload: ", error)
      setFileSave(false)
    }
  }

  const handleFileChange = e => {
    const files = Array.from(e.target.files)
    setFile(files[0])
  }

  const handleSetPricesValue = (priceType, value, splitedName) => {
    if (!priceType || isNil(value) || !splitedName) return;

    setData({
      ...data,
      [priceType]: {
        ...data[priceType],
        [splitedName[1]]: {
          ...data[priceType][splitedName[1]],
          value: Number.isNaN(value) ? '0' : Math.round(
            parseFloat(value).toFixed(2) * 100
          ).toString(),
        },
      },
    })
  };

  const handleChange = (e) => {
    if (e.target.name.includes("name")) {
      const splitedName = e.target.name.split("-")
      setData({
        ...data,
        name: { ...data.name, [splitedName[1]]: e.target.value },
      })
    } else if (e.target.name.includes("gradePrices")) {
      handleSetPricesValue('gradePrices', e.target.value, e.target.name.split("-"));
    } else if (e.target.name.includes("price")) {
      handleSetPricesValue('prices', e.target.value, e.target.name.split("-"));
    } else if (e.target.name === "typeId") {
      const needFormTypesClear = data?.formTypesIds?.length 
      && (
        !data.formTypesIds.every((id) => WITH_SCHOOL_PROG.some((i) => i === id))
        && !data.formTypesIds.every((id) => !WITH_SCHOOL_PROG.some((i) => i === id))
      );
      
      setData({ ...data, [e.target.name]: parseInt(e.target.value, 10), formTypesIds: needFormTypesClear ? [] : data.formTypesIds });
    } else if (e.target.name.includes("description")) {
      const splitedName = e.target.name.split("-")
      setData({
        ...data,
        description: { ...data.description, [splitedName[1]]: e.target.value },
      })
    } else setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleFormTypeIdClick = formId => {
    const { formTypesIds } = data

    const formIdIndex = formTypesIds.findIndex(id => id === formId)
    if (formIdIndex !== -1) {
      formTypesIds.splice(formIdIndex, 1)
      setData({ ...data, formTypesIds })
      return
    }
    setData({ ...data, formTypesIds: [...formTypesIds, formId] })
  }

  const isAllowedToEdit =
    rolePermissions[PERMISSIONS.PERM_PRODUCTS_UPDATE] ||
    (rolePermissions[PERMISSIONS.PERM_PRODUCTS_CREATE] && !selectedProduct)

  const NEED_SCHOOL_ID = data.formTypesIds.some((id) => WITH_SCHOOL_PROG.some((i) => i === id));

  const getPriceField = (name, value, onChange) => (
    <label htmlFor={name}>
      Prix &nbsp;
      <input
        disabled={!isAllowedToEdit}
        id={name}
        type="number"
        name={name}
        step="0.01"
        min="0"
        placeholder="Prix (offert si vide)"
        value={value}
        onChange={onChange}
      />
      &nbsp;euros
    </label>
  );

  return (
    <div className="container-fluid mt-3">
      <button
        className="btn btn-primary btn-sm"
        type="button"
        onClick={onBackToList}
      >
        Retour à la liste
      </button>
      <form onSubmit={handleSubmit}>
        <h1>{selectedProduct ? "Édition produit" : "Nouveau produit"}</h1>
        {formError ? (
          <div className="alert alert-danger">{formError}</div>
        ) : null}

        <fieldset id="general" className="form-group">
          <h2>Informations générales</h2>
          <div className="form-group">
            <label htmlFor="typeId">
              Type(s) de dossier &nbsp;
              {formTypes.map((formType, key) => (
                <div className="form-check" key={key}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={data.formTypesIds.some((id) => id === formType.id)}
                    id={`form_type_${formType.id}`}
                    onChange={() => handleFormTypeIdClick(formType.id)}
                    disabled={
                      data.typeId === 2
                      && data.formTypesIds?.length > 0
                      && !data.formTypesIds.some((id) => id === formType.id)
                      && (
                        (NEED_SCHOOL_ID && !WITH_SCHOOL_PROG.some((id) => id === formType.id))
                        || (!NEED_SCHOOL_ID && WITH_SCHOOL_PROG.some((id) => id === formType.id))
                      )
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`form_type_${formType.id}`}
                  >
                    {formType.code}
                  </label>
                </div>
              ))}
            </label>
          </div>
          {!selectedProduct ? (
            <div className="form-group">
              <label htmlFor="typeId">
                Type &nbsp;
                <select
                  disabled={!isAllowedToEdit}
                  id="typeId"
                  name="typeId"
                  value={data.typeId}
                  onChange={handleChange}
                  required
                >
                  {/* <option value={1}>Programme</option> */}
                  <option value={2}>Package</option>
                  <option value={3}>Hébergement</option>
                  <option value={4}>Interne - Obligatoire</option>
                  <option value={5}>Interne - Optionnel</option>
                  <option value={6}>Option</option>
                </select>
              </label>
            </div>
          ) : null}
          <div className="form-group">
            <label htmlFor="name-1">
              Nom &nbsp;
              <input
                disabled={!isAllowedToEdit}
                id="name-1"
                type="text"
                name="name-1"
                placeholder="Nom en francais"
                value={data.name["1"]}
                onChange={handleChange}
                required
              />
            </label>
            <label htmlFor="name-2">
              <input
                disabled={!isAllowedToEdit}
                id="name-2"
                type="text"
                name="name-2"
                placeholder="Nom en Anglais"
                onChange={handleChange}
                value={data.name["2"]}
                required
              />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="description-1">
              Description &nbsp;
              <textarea
                disabled={!isAllowedToEdit}
                id="description-1"
                name="description-1"
                placeholder="Description en francais"
                value={data.description["1"]}
                onChange={handleChange}
                rows="6"
                style={{ verticalAlign: "top" }}
              />
            </label>
            <label htmlFor="description-2">
              <textarea
                disabled={!isAllowedToEdit}
                id="description-2"
                name="description-2"
                placeholder="Description en Anglais"
                value={data.description["2"]}
                onChange={handleChange}
                rows="6"
                style={{ verticalAlign: "top" }}
              />
            </label>
          </div>
          <div className="form-group">
            <span>Type de prix :&nbsp;</span>
            <label htmlFor="schoolId-1">
              <input
                id="isPricedWeekly-1"
                type="radio"
                name="isPricedWeekly"
                value
                onChange={handleChange}
                checked={data.isPricedWeekly === "true"}
                required
              />
              &nbsp;Semaine&nbsp;
            </label>
            <label htmlFor="isPricedWeekly-2">
              <input
                id="isPricedWeekly-2"
                type="radio"
                name="isPricedWeekly"
                value={false}
                onChange={handleChange}
                checked={data.isPricedWeekly === "false"}
                required
              />
              &nbsp;Année
            </label>
          </div>

          <div className="form-group">
            <div className="form-check">
              <input
                id="isPaidWithDeposit"
                name="isPaidWithDeposit"
                className="form-purcheck-input"
                type="checkbox"
                checked={data.isPaidWithDeposit}
                onChange={handleChange}
              />
              <label
                className="form-check-label"
                htmlFor="isPaidWithDeposit"
              >
                Inclure dans l'acompte
              </label>
            </div>
          </div>
        </fieldset>

        {data.typeId === 3 ? (
          <div className="form-group">
            <label htmlFor="picto">
              Picto &nbsp;
              <input
                disabled={!isAllowedToEdit}
                id="file"
                type="file"
                name="file"
                placeholder="Selectionner un fichier"
                onChange={handleFileChange}
              />
            </label>
            <button
              className="btn btn-success btn-sm"
              type="button"
              onClick={handleFileUpload}
              disabled={!file || fileSave || !isAllowedToEdit}
            >
              Upload
            </button>
          </div>
        ) : null}

        {NEED_SCHOOL_ID ? (
          data?.typeId === 2 ? (
            // PACKAGES
            <>
              <fieldset id="pricing" className="form-group">
                <h2 className="mb-4">Ecole Francaise</h2>
                <div className="form-group d-flex flex-wrap">
                  {schoolGrades.filter((grade) => grade.schoolId === 1).map(({ value, label }, key) => (
                    <div key={key} className="mr-4 mb-4">
                      <strong className="d-block mb-2">{label}</strong>
                      {getPriceField(
                        `gradePrices-${value}`,
                        data?.gradePrices?.[value]?.value ? data.gradePrices[value].value / 100 : '',
                        handleChange
                      )}
                    </div>
                  ))}
                </div>
              </fieldset>

              <fieldset id="pricing" className="form-group">
                <h2 className="mb-4">Ecole Internationale</h2>
                <div className="form-group d-flex flex-wrap">
                  {schoolGrades.filter((grade) => grade.schoolId === 2).map(({ value, label }, key) => (
                    <div key={key} className="mr-4 mb-4">
                      <strong className="d-block mb-2">{label}</strong>
                      {getPriceField(
                        `gradePrices-${value}`,
                        data?.gradePrices?.[value]?.value ? data.gradePrices[value].value / 100 : '',
                        handleChange
                      )}
                    </div>
                  ))}
                </div>
              </fieldset>
            </>
          ) : (
            // OTHER TYPES
            <>
              <fieldset id="pricing" className="form-group">
                <h2 className="mb-4">Ecole Francaise</h2>
                <div className="form-group">
                  {getPriceField('price-1-1', data.prices["1"].value / 100, handleChange)}
                </div>
              </fieldset>

              <fieldset id="pricing" className="form-group">
                <h2 className="mb-4">Ecole Internationale</h2>
                <div className="form-group">
                  {getPriceField('price-2-1', data.prices["2"].value / 100, handleChange)}
                </div>
              </fieldset>
            </>
          )
        ) : (
          <fieldset id="pricing" className="form-group">
            <div className="form-group">
              {getPriceField('price-1-1', data.prices["1"].value / 100, handleChange)}
            </div>
          </fieldset>
        )}

        <button
          type="submit"
          className="btn btn-success btn-sm"
          disabled={formSave || !isAllowedToEdit}
        >
          Sauvegarder
        </button>
      </form>
    </div>
  )
}

ProductsEdit.propTypes = {}

export default ProductsEdit
