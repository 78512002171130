import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

// Utils
import { format, parse } from 'date-fns';
import { fr } from "date-fns/esm/locale"
import { findCountry } from '../../../../utils/coach/findCountry';
import { cloneDeep, isNil } from 'lodash';
import useAppContext from '../../../../hooks/useAppContext';
import Modal from "react-bootstrap4-modal"
import { PERMISSIONS } from '../../../../constants/constants';
import usePermissionsState from '../../../../hooks/usePermissionsState';
import cn from '../../../../utils/cn';

// Components
import WithMenu from '../../../../components/templates/WithMenu/WithMenu';
import Table from '../../../../components/organisms/Table';
import { SelectInput } from '../../../../components/atoms/Inputs/Inputs';
import Select from '../../../../components/atoms/Select';

// Services
import useReportFollowUpApi from '../../../../hooks/Api/useReportFollowUpApi';
import useCountriesApi from '../../../../hooks/Api/useCountriesApi';
import useCoachesApi from '../../../../hooks/Api/useCoachesApi';

import styles from './CoachReports.module.css';

const permissions = [
  PERMISSIONS.PERM_REPORTS_HEAD_COACH
];

const CoachReports = ({ match }) => {
  const { state } = useLocation();
  const { context: { userInfo } } = useAppContext();

  const rolePermissions = usePermissionsState(userInfo, permissions);

  const [, coachesPayload, , { getCoaches }] = useCoachesApi();
  const [getReportsState,,, { getReportsListByCoach }] = useReportFollowUpApi();
  const [,,, { putSaveReport }] = useReportFollowUpApi();
  const [, countries, , { getCountries }] = useCountriesApi();
  const [postUpdateCoachState,,, { postUpdateCoach }] = useReportFollowUpApi();

  const statusOptions = useMemo(() => ([
    { label: 'À compléter', value: 'toComplete' },
    { label: 'En cours', value: 'inProgress' },
    { label: 'Envoyé', value: 'sent' }
  ]), []);

  const [coachId, setCoachId] = useState(match?.params?.id || null);
  const [reports, setReports] = useState([]);
  const [statusFilter, setStatusFilter] = useState([statusOptions[0], statusOptions[1]]);

  const [showUpdateCoachConfirm, setShowUpdateCoachConfirm] = useState(null);
  const [hasUpdateCoachError, setHasUpdateCoachError] = useState(false);

  const populateReports = (coachId) => {
    getReportsListByCoach(coachId).then((res) => {
      if (res?.data?.reports) setReports(res.data.reports);
    });
    getCoaches();
    getCountries();
  };

  useEffect(() => {
    if (coachId) {
      populateReports(coachId);
    }
  }, []);

  useEffect(() => {
    if (userInfo?.sub && !coachId) {
      populateReports(userInfo.sub);
      setCoachId(userInfo.sub);
    }
  }, [userInfo]);

  const filteredReports = useMemo(() => {
    const statusFilterValues = statusFilter?.map((filter) => filter.value) || [];

    return reports?.filter((report) => {
      const isBlank = !report.hasBeenDeliveredToPlayer && report.isBlank;
      const isInProgress = !report.hasBeenDeliveredToPlayer && !report.isBlank;
      const isSent = report.hasBeenDeliveredToPlayer;

      if (statusFilterValues.some((value) => value === 'toComplete') && isBlank) return true;
      if (statusFilterValues.some((value) => value === 'inProgress') && isInProgress) return true;
      if (statusFilterValues.some((value) => value === 'sent') && isSent) return true;

      return false;
    }) || [];
  }, [reports, statusFilter]);

  const handleChangeTemplateLang = (report, languageCode) => {
    putSaveReport(report.id, { ...report, languageCode }).then((res) => {
      if (res?.data) {
        const updatedReport = res.data;
        const reportIndex = reports?.findIndex((r) => r.id === updatedReport.id);
        if (updatedReport?.languageCode && reportIndex > -1) {
          const updatedList = cloneDeep(reports);
          if (updatedList[reportIndex]) {
            updatedList[reportIndex].languageCode = updatedReport.languageCode;
            setReports(updatedList);
          }
        }
      }
    });
  };

  const getCoach = () => coachesPayload?.coaches?.find((c) => c.id === coachId) || null;

  const isAllowedToUpdateCoach = rolePermissions[PERMISSIONS.PERM_REPORTS_HEAD_COACH];

  const handleUpdateCoach = ({ reportId, newCoachId }) => {
    postUpdateCoach(reportId, newCoachId).then((res) => {
      setShowUpdateCoachConfirm(null);
      if (res?.status === 204) {
        getReportsListByCoach(coachId).then((res) => {
          if (res?.data?.reports) setReports(res.data.reports);
        });
      } else {
        setHasUpdateCoachError(true);
      }
    });
  };

  return (
    <WithMenu>
      <div className='mt-4 mb-4'>
        <div className='mr-5'>
          <h1 className='mb-3'>
            Rapports joueurs

            {getCoach() ? (
              ` - ${getCoach().firstName} ${getCoach().lastName}`
            ) : null}
          </h1>
        </div>

        <div className='d-flex mt-4 mb-5'>
          <div className={`${styles.filter}`}>
            <label>
              <b>Filtrer par statut</b>
            </label>
            <Select
              options={statusOptions}
              placeholder="Statut du rapport"
              onChange={(val) => setStatusFilter(val)}
              value={statusFilter}
              isMulti
              noOptionsMessage={() => <span>Aucun résultat</span>}
            />
          </div>
        </div>

        {state && state.success && (
          <div className="alert alert-success" role="alert">
            Le rapport a bien été envoyé.
          </div>
        )}

        {hasUpdateCoachError ? (
          <div className="alert alert-danger" role="alert">
            Une erreur s'est produite lors de la réaffectation du coach. Merci de réessayer.
          </div>
        ) : null}

        {getReportsState === 'loading' || isNil(isAllowedToUpdateCoach) ? (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          filteredReports?.length ? (
            <Table
              columns={[
                {
                  Header: "Date début stage",
                  accessor: "start",
                  Cell: ({
                    cell: {
                      row: {
                        original: { weekCode },
                      },
                    },
                  }) => (
                    <div>
                      {weekCode && parse(weekCode, 'yyyy-MM-dd', new Date()) ? (
                        format(
                          parse(weekCode, 'yyyy-MM-dd', new Date()),
                          "dd MMMM yyyy",
                          { locale: fr }
                        )
                      ) : null}
                    </div>
                  )
                },
                {
                  Header: "Nom du joueur",
                  accessor: "player",
                  Cell: ({
                    cell: {
                      row: {
                        original: {
                          player: { firstName, lastName }
                        },
                      },
                    },
                  }) => (
                    <strong>{`${firstName} ${lastName}`}</strong>
                  ),
                },
                {
                  Header: "Nationalité",
                  accessor: "nationalityCountryCode",
                  Cell: ({
                    cell: {
                      row: {
                        original: { 
                          player: { nationalityCountryCode }
                        },
                      },
                    },
                  }) => {
                    return (
                      <div>
                        {findCountry(countries?.countries, nationalityCountryCode)}
                      </div>
                    )}
                },
                {
                  Header: "Présent sem. pro",
                  accessor: "hasBookingNextWeek",
                  Cell: ({
                    cell: {
                      row: {
                        original: { 
                          player: { hasBookingNextWeek }
                        },
                      },
                    },
                  }) => {
                    return (
                      <div>
                        <input type="checkbox" readOnly checked={hasBookingNextWeek} />
                      </div>
                    )}
                },
                {
                  Header: "Template",
                  accessor: "languageCode",
                  Cell: ({
                    cell: {
                    row: {
                      original,
                    },
                  },
                }) => {
                  return (
                    !original.hasBeenDeliveredToPlayer ? (
                      <div>
                        <SelectInput
                          id="languageCode"
                          name="languageCode"
                          onChange={(val) => {
                            const langCode = val.target?.value
                            if (original && langCode) {
                              handleChangeTemplateLang(original, langCode)
                            }
                          }}
                          value={original?.languageCode}
                          options={[
                            <option value="FR" key="FR">Template FR</option>,
                            <option value="EN" key="EN">Template EN</option>
                          ]}
                          className={styles.select}
                        />
                      </div>
                    ) : <div />
                  )}
                },
                {
                  Header: "Éditer",
                  accessor: "edit",
                  Cell: ({
                    cell: {
                    row: {
                      original: { id, hasBeenDeliveredToPlayer },
                    },
                  },
                  }) => (
                    !hasBeenDeliveredToPlayer ? (
                      <div
                        className="btn-group btn-group-sm"
                        role="group"
                        aria-label="View more"
                      >
                        <Link
                          className="btn btn-secondary"
                          to={`/reports/edit/${id}`}
                        >
                          Éditer
                        </Link>
                      </div>
                    ) : <div />
                  ),
                },
                {
                  Header: "Réaffecter",
                  accessor: "coachId",
                  Cell: ({
                    cell: {
                    row: {
                      original: { id, player, hasBeenDeliveredToPlayer },
                    },
                  },
                  }) => (
                    !hasBeenDeliveredToPlayer ? (
                      <div>
                        <SelectInput
                          id="coachId"
                          name="coachId"
                          onChange={(input) => {
                            const coachIdValue = input?.target?.value;

                            if (coachIdValue && id && player) {
                              setShowUpdateCoachConfirm({ reportId: id, newCoachId: coachIdValue, player: { firstName: player.firstName, lastName: player.lastName } });
                            }
                          }}
                          value={getCoach()?.id}
                          options={coachesPayload?.coaches?.sort((a,b)=>
                              (a.firstName.concat(a.lastName)).toLowerCase().localeCompare((b.firstName.concat(b.lastName)).toLowerCase()))
                              .map((coach) => (
                            <option value={coach.id} key={coach.id}>
                              {`${coach.firstName} ${coach.lastName}`}
                            </option>
                          ))}
                          className={styles.select}
                          disabled={!coachesPayload?.coaches?.length}
                        />
                      </div>
                    ) : <div />
                  ),
                },
                {
                  Header: "Statut",
                  accessor: "isBlank",
                  Cell: ({
                    cell: {
                    row: {
                      original: { isBlank, hasBeenDeliveredToPlayer },
                    },
                  },
                  }) => (
                    <div 
                      className={cn([
                        styles.default,
                        !hasBeenDeliveredToPlayer && isBlank ? styles.highlight : '',
                        hasBeenDeliveredToPlayer ? styles.sent : ''
                      ])}
                    >
                      {hasBeenDeliveredToPlayer ? 'Envoyé' : (
                        isBlank ? 'À compléter' : 'En cours'
                      )}
                    </div>
                  ),
                }
              ]}
              data={filteredReports}
              hiddenColumns={isAllowedToUpdateCoach ? [] : ['coachId']}
            />
          ) : (
            <p className={styles.empty}>Aucun résultat à afficher.</p>
          )
        )}

        <Modal
          visible={
            !isNil(showUpdateCoachConfirm)
            && showUpdateCoachConfirm?.newCoachId
            && showUpdateCoachConfirm?.reportId
          }
          dialogClassName="modal-confirmation modal-update-coach"
        >
          <div className="modal-header">
            <div className="text-xl modal-header-title">
              <b>
                Réaffecter le rapport
              </b>
            </div>
            <div
              className="close"
              data-dismiss="modal"
              onClick={() => setShowUpdateCoachConfirm(null)}
            >
              <span className="icon-cross" aria-hidden="true" />
              <span className="sr-only">Close</span>
            </div>
          </div>

          <div className="modal-body confirm-finalize-body">
            {`Souhaitez-vous réaffecter ce rapport (${showUpdateCoachConfirm?.player?.firstName} ${showUpdateCoachConfirm?.player?.lastName}) au coach ${coachesPayload?.coaches?.find((c) => c.id === showUpdateCoachConfirm?.newCoachId)?.firstName} ${coachesPayload?.coaches?.find((c) => c.id === showUpdateCoachConfirm?.newCoachId)?.lastName} ?`}
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-default btn-md"
              onClick={() => setShowUpdateCoachConfirm(null)}
            >
              Annuler
            </button>
            <button
              className="btn btn-primary btn-md"
              onClick={() => handleUpdateCoach(showUpdateCoachConfirm)}
              disabled={postUpdateCoachState === 'loading'}
            >
              {postUpdateCoachState === 'loading' ? (
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              Oui, valider
            </button>
          </div>
        </Modal>
      </div>
    </WithMenu>
  )
}

CoachReports.propTypes = {}

export default CoachReports