import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// Components
import WithMenu from '../../../../components/templates/WithMenu/WithMenu'
import Table from '../../../../components/organisms/Table'

// Services
import useCoachesApi from '../../../../hooks/Api/useCoachesApi'
import Select from '../../../../components/atoms/Select/Select'

import styles from './HeadCoach.module.css';

const HeadCoach = () => {
  const [getCoachesState, coachesPayload, , { getCoaches }] = useCoachesApi();

  const [selectedCoaches, setSelectedCoaches] = useState([]);

  useEffect(() => {
    getCoaches();
  }, []);

  const getFilteredCoaches = (list) => (selectedCoaches?.length > 0 ? list.filter((c) => selectedCoaches.map((c) => c.value).includes(c.id)) : list);

  return (
    <WithMenu>
      <div className='mt-4 mb-4'>
        <h1 className='mb-3'>Head coach / Suivi des rapports</h1>
        <div className='d-flex mb-5'>
          <div className={`${styles.select}`}>
            <Select
              options={coachesPayload?.coaches?.length 
                ? coachesPayload.coaches
                  .sort((a,b)=>
                    (a.firstName.concat(a.lastName)).toLowerCase().localeCompare((b.firstName.concat(b.lastName)).toLowerCase()))
                  .map((coach) =>
                    ({ label: `${coach.firstName} ${coach.lastName}`, value: coach.id }))
                : []}
              placeholder="Filtrer par coach"
              isSearchable
              isClearable
              isMulti
              noOptionsMessage={() => <span>Aucun résultat</span>}
              onChange={(val) => setSelectedCoaches(val)}
              value={selectedCoaches || []}
            />
          </div>
        </div>

        {getCoachesState === 'loading' ? (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          getFilteredCoaches(coachesPayload?.coaches || [])?.length ? (
            <Table
              columns={[
                {
                  Header: "Coach",
                  accessor: "name",
                  Cell: ({
                    cell: {
                      row: {
                        original: { firstName, lastName },
                      },
                    },
                  }) => (
                    <strong>{`${firstName} ${lastName}`}</strong>
                  ),
                },
                {
                  Header: "À faire",
                  accessor: "countOfBlankReports",
                  Cell: ({
                    cell: {
                      row: {
                        original: { countOfToDoReports, countOfBlankReports },
                      },
                    },
                  }) => `${(countOfBlankReports)} / ${countOfToDoReports}`,
                },
                {
                  Header: "En cours",
                  accessor: "countOfToDoReports",
                  Cell: ({
                    cell: {
                      row: {
                        original: { countOfToDoReports, countOfBlankReports },
                      },
                    },
                  }) => `${(countOfToDoReports - countOfBlankReports)} / ${countOfToDoReports}`,
                },
                {
                  Header: "En retard",
                  accessor: "countOfLateReports",
                  Cell: ({
                    cell: {
                      row: {
                        original: { countOfToDoReports, countOfLateReports },
                      },
                    },
                  }) => `${(countOfLateReports)} / ${countOfToDoReports}`,
                },
                {
                  Header: "",
                  accessor: "more",
                  Cell: ({
                    cell: {
                      row: {
                        original: { id },
                      },
                    },
                  }) => (
                    <div
                      className="btn-group btn-group-sm"
                      role="group"
                      aria-label="View more"
                    >
                      <Link
                        className="btn btn-secondary"
                        to={`/reports/coach/${id}`}
                      >
                        Voir plus
                      </Link>
                    </div>
                  ),
                },
              ]}
              data={getFilteredCoaches(coachesPayload?.coaches || [])}
            />
          ) : <p className={styles.empty}>Aucun résultat à afficher.</p>
        )}
      </div>
    </WithMenu>
  )
}

HeadCoach.propTypes = {}

export default HeadCoach
